<template>
	<div id="appReport" style="background:#f3f3f3;">
		<div class="tools">
			<button @click="pdf()" v-if="pageInfo.isTestAccount==0||this.$route.query.admin == 1">导出PDF</button>
		</div>
		<div id="pageContent">
			<!-- 封面 -->
			<div class="homePage page">
				<div class="content" id="homePage">
					<div class="logoBox">
						<h1 id="logo"></h1>
						<h4>报告编号：{{pageInfo.reportNumber}}</h4>
						<div class="stock-logo hide">
							<img src="" alt="股交所logo-心流" />
						</div>
					</div>
					<p class="companyName" v-text="pageInfo.baseInfo.companyName"></p>
					<div class="homePageLine"></div>
					<h2>图灵估值报告</h2>
					<h3>TURING VALUATION REPORT</h3>
					<div class="page-one-footer">
						<div class="date" v-text="pageInfo.createTime ? replaceAll(pageInfo.createTime) : ''"></div>
						<p>
							<span>北京心流慧估科技有限公司</span>
							<span>|</span>
							<span>心流保留对此报告的最终解释权</span>
						</p>
					</div>
				</div>
			</div>
			<!--目录页-->
			<div class="page">
				<div class="content page-catalogue" id="page-catalogue">
					<h2>目录</h2>
					<div class="generalize">
						<div>
							<p>1</p>
							<div></div>
							<p>估值结果</p>
							<span class="dottedLine"></span>
							<span class="pageNumber pageNumActive">01</span>
						</div>
						<div>
							<p>2</p>
							<div></div>
							<p>基本信息</p>
							<span class="dottedLine"></span>
							<span class="pageNumber pageNumActive">02</span>
						</div>
						<div>
							<p>3</p>
							<div></div>
							<p>机器学习估值法</p>
							<span class="dottedLine" style="width: 662px;"></span>
							<span class="pageNumber pageNumActive">03</span>
						</div>
						<div>
							<p>4</p>
							<div></div>
							<p>绝对估值法</p>
							<span class="dottedLine" style="width: 710px;"></span>
							<span class="pageNumber pageNumActive">04</span>
						</div>
						<div>
							<p>5</p>
							<div></div>
							<p>软性指标</p>
							<span style="width: 734px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">06</span>
						</div>
						<ul>
							<li>
								<p>行业</p>
								<span class="dottedLine" style="width: 794px;"></span>
								<span class="pageNumber">06</span>
							</li>
							<li>
								<p>产品</p>
								<span class="dottedLine" style="width: 794px;"></span>
								<span class="pageNumber">06</span>
							</li>
							<li>
								<p>团队</p>
								<span class="dottedLine" style="width: 794px;"></span>
								<span class="pageNumber">07</span>
							</li>
							<li>
								<p>技术</p>
								<span class="dottedLine" style="width: 794px;"></span>
								<span class="pageNumber">07</span>
							</li>
							<li>
								<p>风控</p>
								<span class="dottedLine" style="width: 794px;"></span>
								<span class="pageNumber">07</span>
							</li>
						</ul>
						<div>
							<p>6</p>
							<div></div>
							<p>财务分析</p>
							<span style="width: 734px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">08</span>
						</div>
						<ul>
							<li>
								<p>偿债分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">08</span>
							</li>
							<li>
								<p>盈利分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">10</span>
							</li>
							<li>
								<p>成长分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">11</span>
							</li>
							<li>
								<p>营运分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">12</span>
							</li>
							<li>
								<p>杜邦分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">13</span>
							</li>
						</ul>
						<div>
							<p>7</p>
							<div></div>
							<p>体检结果</p>
							<span style="width: 734px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">14</span>
						</div>
						<div>
							<p>8</p>
							<div></div>
							<p>附件</p>
							<span style="width: 780px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">15</span>
						</div>
						<div class="stockIntroduce hide">
							<p>6</p>
							<div></div>
							<p class="stock_introductory">股交所介绍</p>
							<span style="width: 712px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">07</span>
						</div>
						<div>
							<p class="catalogueHeader">9</p>
							<div></div>
							<p>关于心流慧估</p>
							<span style="width: 688px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive catalogueFoot">18</span>
						</div>
					</div>
				</div>
			</div>
			<!--盖章页-->
			<div class="page">
				<!--内容-->
				<div class="content" id="page-stamp">
					<p class="stamp">
						依据《企业会计准则第22号--金融工具确认和计量》、《企业会计准则第39号--公允价值计量》、《私募投资基金非上市股权投资估值指引》和《中小微企业估值规范》等政策规章，基于股权估值模型和估值指标体系，贵公司在估值基准日的股权价值为（人民币）：
					</p>
					<div class="detail_result">
						<div>
							<h4 v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">0.00</h4>
						</div>
					</div>
					<div class="stamptime">
						<p>北京心流慧估科技有限公司</p>
						<p v-text="pageInfo.updateTime ? dateFormate(pageInfo.updateTime) : dateFormate(pageInfo.createTime)">
						</p>
					</div>
				</div>
			</div>
			<!-- 估值结果页 -->
			<div class="page">
				<div class="content page-content" id="page-one">
					<!--估值结果-->
					<div class="valueResultTitle">
						<img src="../../assets/img/report/valueResult.png" class="icon_" alt="心流">
						<span>估值结果</span>
					</div>
					<div class="valueResult">
						<div class="valueResultCont">
							<img src="../../assets/img/report/numberBox.png" alt="心流-图灵报告">
							<div class="numberBox">
								<img v-for="(item, index) in valueResultNumber" :key="index + 1"
									:src="require('../../assets/img/report/'+item.val+'.png')" :style="'margin-top:'+item.top"
									alt="估值结果数字-心流">
								<p class="unit">{{method.switchNum(pageInfo.minValue, pageInfo.maxValue).unit}}</p>
							</div>

						</div>
					</div>
					<div class="sampleGraph">
						<img src="../../assets/img/report/sampleGraph1.png" alt="机器学习估值法-心流">
						<img src="../../assets/img/report/sampleGraph8.png" alt="机器学习估值法-心流">
						<div>
							<p class="exampleExplanation">机器学习估值法</p>
							<p class="exampleData"
								v-text="pageInfo.valuationReplacement==0?valueFormate(pageInfo.turingValuationValue):valueFormate(pageInfo.relativeValuationValue)">
								xxx</p>
						</div>
						<img src="../../assets/img/report/sampleGraph2.png" alt="绝对估值法-心流">
						<img src="../../assets/img/report/sampleGraph9.png" alt="绝对估值法-心流">
						<div>
							<p class="exampleExplanation">绝对估值法</p>
							<p class="exampleData" v-text="valueFormate(pageInfo.absoluteValuation.ggm)">1.20亿元</p>
						</div>
						<img src="../../assets/img/report/sampleGraph3.png" alt="企业综合得分-心流">
						<img src="../../assets/img/report/sampleGraph10.png" alt="企业综合得分-心流">
						<div>
							<p class="exampleExplanation">企业综合得分</p>
							<p class="exampleData">{{pageInfo.totalScore}}分</p>
						</div>
						<img src="../../assets/img/report/sampleGraph4.png" alt="流动性折价-心流">
						<img src="../../assets/img/report/sampleGraph11.png" alt="流动性折价-心流">
						<div>
							<p class="exampleExplanation">流动性折价</p>
						</div>
						<img src="../../assets/img/report/sampleGraph5.png" alt="心流指数倍数-心流">
						<img src="../../assets/img/report/sampleGraph10.png" alt="心流指数倍数-心流">
						<div>
							<p class="exampleExplanation">心流指数倍数</p>
							<p class="exampleExplanation">修正区间</p>
						</div>
						<img src="../../assets/img/report/sampleGraph6.png" alt="心流指数倍数-心流">
						<img src="../../assets/img/report/sampleGraph12.png" alt="心流指数倍数-心流">
						<div>
							<p class="exampleExplanation">折价后结果</p>
							<p class="exampleData" v-text="pageInfo.relaValueRatio?valueFormate(pageInfo.relaValueRatio):'无数据'"></p>
						</div>
						<img src="../../assets/img/report/sampleGraph7.png" alt="心流指数倍数-心流">
						<div>
							<p class="exampleExplanation">估值结果</p>
							<p class="exampleData" v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)"></p>
						</div>
					</div>
					<p>企业最终的估值结果为综合考虑机器学习估值结果、绝对估值结果、流动性折价因素、修正指数倍数后得出。</p>
					<ul style="list-style-type:decimal">
						<li>1、流动性折价率综合考虑了非上市企业股权转售时的流动性风险，系统根据不同行业制定差异性的流动性折价率；</li>
						<li>2、修正指数倍数区间是由影响企业价值的内外因素通过量化指标综合打分得出，包括软性指标和财务指标分析两部分，具体参照心流图谱和财务分析部分。</li>
					</ul>
					<div class="pagination">1</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-two">
					<div class="baseInfo">
						<div class="valueResultTitle">
							<img src="../../assets/img/report/icon1.png" class="icon_" alt="心流">
							<span>企业基本信息</span>
						</div>
						<div class="baseInfoCont">
							<ul>
								<li>
									<span style="vertical-align: top;">企业名称：</span>
									<span id="companyName" style="width: 77%;" v-text="pageInfo.baseInfo.companyName"></span>
								</li>
								<li>
									<span style="vertical-align: top;">所属行业：</span>
									<span id="industry" style="width: 77%;"
										v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName"></span>
								</li>
								<li>
									<span>企业所在地：</span>
									<span id="companyLocation" v-text="pageInfo.baseInfo.area ? pageInfo.baseInfo.area : '无数据'"></span>
								</li>
								<li>
									<span>法定代表人：</span>
									<span id="corporateRepresentative"
										v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople : '无数据'"></span>
								</li>
								<li>
									<span>统一社会信用代码：</span>
									<span id="socialCreditCode" v-text="pageInfo.baseInfo.creditCode"></span>
								</li>
								<li>
									<span>成立日期：</span>
									<span id="establishmentDate" v-text="dateFormate(pageInfo.baseInfo.establishDate)"></span>
								</li>
								<li>
									<span>邮箱：</span>
									<span id="Email" v-text="pageInfo.baseInfo.email"></span>
								</li>
								<li>
									<span>企业价值（自评）：</span>
									<span id="companyValuation" v-text="valueFormate(pageInfo.baseInfo.selfValue)"></span>
								</li>
							</ul>
						</div>
						<div class="statePlay">
							<div>
								<div class="valueResultTitle">
									<img src="../../assets/img/report/icon2.png" class="icon_" alt="心流">
									<span>发展情况</span>
								</div>
								<div class="productCont">
									<ul>
										<li>
											<span>所处周期：</span>
											<span id="productStage" v-text="pageInfo.period"></span>
										</li>
										<li>
											<span>产品推广：</span>
											<span id="productType"
												v-text="pageInfo.baseInfo.extendMarketFlag == 1 ? '已推广到市场' : '未推广到市场'"></span>
										</li>
										<li>
											<span>盈亏情况：</span>
											<span id="clientFeelback" v-text="pageInfo.profitLoss?pageInfo.profitLoss:'无数据'"></span>
										</li>
									</ul>
								</div>
							</div>
							<div>
								<div class="valueResultTitle">
									<img src="../../assets/img/report/icon3.png" class="icon_" alt="心流">
									<span>企业家素质</span>
								</div>
								<div class="groupCont">
									<ul>
										<li>
											<span>行业经验：</span>
											<span id="managerialExperience"
												v-text="pageInfo.industryExperience?pageInfo.industryExperience:'无数据'"></span>
										</li>
										<li>
											<span>创业经验：</span>
											<span id="industryExperience" v-text="pageInfo.startExperience == 1 ? '有' : '无'"></span>
										</li>
										<li>
											<span>创始人学历：</span>
											<span id="originatorEducation"
												v-text="pageInfo.baseInfo.founderEdu?pageInfo.baseInfo.founderEdu:'无数据'"></span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="mainBusiness">
							<div class="valueResultTitle">
								<img src="../../assets/img/report/icon4.png" class="icon_" alt="心流">
								<span>主营业务</span>
							</div>
							<div class="mainBusinessCont">
								<p class="bussinessDesc"
									v-text="!pageInfo.baseInfo.bussinessDesc ?'暂无数据':pageInfo.baseInfo.bussinessDesc.length>100? pageInfo.baseInfo.bussinessDesc.substring(0, 100)+'...' :pageInfo.baseInfo.bussinessDesc ">
								</p>
							</div>
						</div>
						<div>
							<div class="valueResultTitle">
								<img src="../../assets/img/report/icon5.png" class="icon_" alt="心流">
								<span>拟融资</span>
							</div>
							<div class="situationCont">
								<ul>
									<li>
										<span>拟融资额度：</span>
										<span id="financingLimit"
											v-text="pageInfo.baseInfo.finance ? formatCurrency.formateNum(pageInfo.baseInfo.finance)+'万元' : '无数据'"></span>
									</li>
									<li>
										<span>计划出让股权：</span>
										<span id="concessionStock"
											v-text="pageInfo.baseInfo.ratio ? pageInfo.baseInfo.ratio + '%' : '无数据'"></span>
									</li>
								</ul>
							</div>
						</div>
						<div class="equityStructure">
							<div class="valueResultTitle">
								<img src="../../assets/img/report/icon6.png" class="icon_" alt="心流">
								<span>股权结构</span>
							</div>
							<img src="../../assets/img/report/chart.png" class="piePedestal" alt="饼图-心流">
							<Draw3DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.baseInfo.holderRation"
								v-if="initStatus" />
						</div>
					</div>
					<div class="pagination">2</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-three">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon7.png" class="icon_" alt="心流">
						<span>机器学习估值法</span>
					</div>
					<ul>
						<li>
							1、模型基于上市和非上市公司数据，选取合适的估值、统计分析方法标注形成数据集。
						</li>
						<li>
							2、心流深度学习估值采用神经网络，以心流估值数据集为样本，选取盈利能力、偿债能力、成长能力、营运能力等财务估值指标为影响因子，对估值模型训练。
						</li>
						<li>
							3、心流深度学习估值模型利用神经网络，通过采用梯度下降法最小化损失函数对网络中的权重参数逐层反向调节，通过对训练数据随时间的推移不断学习并提高准确性。利用计算科学智能地计算企业价值。
						</li>
					</ul>
					<div class="profitData" id="profitData">
						<svg width='480' height='403'>
							<text class="rotateYear" :x="profitDataYearX[index]" y="65" transform='rotate(-12 280 100)'
								v-for="(item, index) in pageInfo.year">{{item}}</text>
							<text class="rotateData" :x="profitDataIncomeX[index]" y="122" transform='rotate(-12 280 100)'
								v-for="(item, index) in income" :key="index">{{formatCurrency.formateNum(item, 2)}}</text>
							<text class="rotateDataBlue" :x="profitDataCostX[index]" y="164" transform='rotate(-12 280 100)'
								v-for="(item, index) in cost" :key="index+'1'">{{formatCurrency.formateNum(item, 2)}}</text>
							<text class="rotateData" :x="businessProfitX[index]" y="204" transform='rotate(-12 280 100)'
								v-for="(item, index) in businessProfit" :key="index +'2'">{{formatCurrency.formateNum(item, 2)}}</text>
							<text class="rotateDataBlue" :x="totalProfitX[index]" y="240" transform='rotate(-12 280 100)'
								v-for="(item, index) in totalProfit" :key="index +'3'">{{formatCurrency.formateNum(item, 2)}}</text>
							<text class="rotateData" :x="netProfitX[index]" y="278" transform='rotate(-13 280 110)'
								v-for="(item, index) in netProfit" :key="index+'4'">{{formatCurrency.formateNum(item, 2)}}</text>
						</svg>
					</div>
					<img class="arrows" src="../../assets/img/report/arrows.svg" alt="箭头-心流">
					<img src="../../assets/img/report/machineLearningNew.png" alt="机器学习公式-心流">
					<img class="arrows" src="../../assets/img/report/arrows.svg" alt="箭头-心流">
					<div class="machineLearningValuationResults specialNumeric">
						<img src="../../assets/img/report/technologyBox.png" alt="机器学习估值结果-心流">
						<div class="numberBox">
							<span class="valuationMethod">机器学习估值法结果：</span>
							<img v-for="(item, index) in machineLearningValuationResults" :key="index + 1"
								:src="require('../../assets/img/report/'+item.val+'.png')" :style="'margin-top:'+item.top"
								alt="估值结果数字-心流">
							<p class="unit">
								{{pageInfo.valuationReplacement==0?method.technologyNum(pageInfo.turingValuationValue).unit:method.technologyNum(pageInfo.relativeValuationValue).unit}}
							</p>
						</div>
					</div>
					<div class="pagination">3</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-four">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon8.png" class="icon_" alt="心流">
						<span>绝对估值法</span>
					</div>
					<ul>
						<li>
							1、绝对估值法：心流采用DCF(Discount Cash Flow)中FCFF(Free Cash Flow to Firm)模型和GGM (Gorden Growth
							Model)模型相结合，对企业无杠杆自由现金流及终值进行折现，继而对企业进行价值评估。
						</li>
						<li>
							2、在对企业终值测算时，心流采用GGM(Gorden Growth Model)模型。
						</li>
						<li>
							3、WACC(加权平均资本成本)是心流综合考虑企业所在行业特性，杠杆比率，股权成本，债务成本等因素后，计算出的总资本成本。
						</li>
						<li>
							4、当FCFF计算估值结果为负值时，则该估值方法不适用。
						</li>
					</ul>
					<!--图表部分-->
					<div class="table_area">
						<DrawPileBar :id="'cashFlowforecast'" class="cash-flow" :chartData="pageInfo.absoluteValuation"
							:year="nowYear" v-if="initStatus" />
						<div class="cash-flow center-arrow">
							<img src="../../assets/img/report/equal@2x.png" alt="线上估值-心流" />
						</div>
						<DrawBarLine :id="'cashFlowTrend'" class="cash-flow" :chartData="pageInfo.absoluteValuation" :year="nowYear"
							v-if="initStatus" />
					</div>
					<!--虚线框部分-->
					<div class="dashes-line"></div>
					<!--箭头指示-->
					<div class="arrow-down">
						<p>WACC<br /><span v-text="(pageInfo.absoluteValuation.wacc * 100).toFixed(2) + '%'"></span></p>
					</div>
					<div class="absoluteValuationMethod specialNumeric">
						<img src="../../assets/img/report/technologyBox2.png" alt="绝对估值法结果-心流">
						<div class="numberBox">
							<span class="valuationMethod">绝对估值法结果：</span>
							<img v-for="(item, index) in absoluteValuationResult" :key="index + 1"
								:src="require('../../assets/img/report/'+item.val+'.png')" :style="'margin-top:'+item.top"
								alt="估值结果数字-心流">
							<p class="unit">{{method.technologyNum(pageInfo.absoluteValuation.ggm).unit}}</p>
						</div>
					</div>
					<div class="pagination">4</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-five">
					<div class="radarImage">
						<div class="valueResultTitle">
							<img src="../../assets/img/report/icon9.png" class="icon_" alt="心流">
							<span>心流图谱</span>
						</div>
						<img src="../../assets/img/report/radarBase.png" alt="雷达图底座-心流">
						<DrawRadarCanvas :chartData="pageInfo.question" :id="'flowDiagnose_BaseInfo'" v-if="initStatus" />
					</div>
					<div class="radarImage">
						<div class="valueResultTitle">
							<img src="../../assets/img/report/icon10.png" class="icon_" alt="心流">
							<span>财务分析</span>
						</div>
						<img src="../../assets/img/report/radarBase.png" alt="雷达图底座-心流">
						<DrawRadarCanvas :chartData="pageInfo.financial" :id="'financeAnalyze_BaseInfo'" v-if="initStatus" />
					</div>
					<div class="pagination">5</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-six">
					<!--分值说明-->
					<div class="socre-explain-container">
						<p>
							<span>分值说明</span>
						</p>
						<p>
							<span id="socre-explain" v-text="scoreText"></span>
						</p>
					</div>
					<div class="flexibleIndex">
						<div class="industryTitle">
							<img src="../../assets/img/report/icon11.png" class="icon_" alt="心流">
							<p>
								<span>行业</span>
								<span>{{scoreFormate('行业')}}</span>
							</p>
						</div>
						<ul>
							<li v-for="(item,index) in pageInfo.industry" :key="index + 2">
								<div class="grade">
									<img src="../../assets/img/report/industryScore.png" alt="行业-心流">
									<p>{{item.value}}</p>
									<p>{{item.name}}</p>
								</div>
								<div class="industryDes">
									<p>
										<span>0{{index+1}}</span>
										<span>{{item.name}}</span>
									</p>
									<p>{{item.description}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="flexibleIndex">
						<div class="industryTitle">
							<img src="../../assets/img/report/icon12.png" class="icon_" alt="心流">
							<p>
								<span>产品</span>
								<span>{{scoreFormate('产品')}}</span>
							</p>
						</div>
						<ul>
							<li v-for="(item,index) in pageInfo.competitivePower" :key="index + 2">
								<div class="grade">
									<img src="../../assets/img/report/industryScore2.svg" alt="产品-心流">
									<p>{{item.value}}</p>
									<p>{{item.name}}</p>
								</div>
								<div class="industryDes">
									<p>
										<span>0{{index+1}}</span>
										<span>{{item.name}}</span>
									</p>
									<p>{{item.description}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="pagination">6</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-seven">
					<div class="flexibleIndex">
						<div class="industryTitle">
							<img src="../../assets/img/report/icon13.png" class="icon_" alt="心流">
							<p>
								<span>团队</span>
								<span>{{scoreFormate('团队')}}</span>
							</p>
						</div>
						<ul>
							<li v-for="(item,index) in pageInfo.teamManage" :key="index + 2">
								<div class="grade">
									<img src="../../assets/img/report/industryScore3.png" style="bottom: 7px;" alt="行业-心流">
									<p>{{item.value}}</p>
									<p>{{item.name}}</p>
								</div>
								<div class="industryDes">
									<p>
										<span>0{{index+1}}</span>
										<span>{{item.name}}</span>
									</p>
									<p>{{item.description}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="flexibleIndex">
						<div class="industryTitle">
							<img src="../../assets/img/report/icon14.png" class="icon_" alt="心流">
							<p>
								<span>技术</span>
								<span>{{scoreFormate('技术')}}</span>
							</p>
						</div>
						<ul>
							<li v-for="(item,index) in pageInfo.technology" :key="index + 2">
								<div class="grade">
									<img src="../../assets/img/report/industryScore5.svg" style="bottom: 35px;" alt="行业-心流">
									<p>{{item.value}}</p>
									<p>{{item.name}}</p>
								</div>
								<div class="industryDes">
									<p>
										<span>0{{index+1}}</span>
										<span>{{item.name}}</span>
									</p>
									<p>{{item.description}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="flexibleIndex">
						<div class="industryTitle">
							<img src="../../assets/img/report/icon15.png" class="icon_" alt="心流">
							<p>
								<span>风控</span>
								<span>{{scoreFormate('风控')}}</span>
							</p>
						</div>
						<ul>
							<li v-for="(item,index) in pageInfo.ventureManage" :key="index + 2">
								<div class="grade">
									<img src="../../assets/img/report/industryScore4.svg" style="bottom: 10px;" alt="行业-心流">
									<p>{{item.value}}</p>
									<p>{{item.name}}</p>
								</div>
								<div class="industryDes">
									<p>
										<span>0{{index+1}}</span>
										<span>{{item.name}}</span>
									</p>
									<p>{{item.description}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="pagination">7</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-eight">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon16.png" class="icon_" alt="心流">
						<span>偿债分析</span>
					</div>
					<DrawBarFinancialAnalysis2v :id="'liabilitiesRatio'" :chartData="pageInfo.liabilitiesRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<DrawBarFinancialAnalysis2v :id="'flowRation'" :chartData="pageInfo.flowRation" v-if="initStatus"
						:gradeStatus="gradeStatus" />

					<div class="pagination">8</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-nine">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon16.png" class="icon_" alt="心流">
						<span>偿债分析</span>
					</div>
					<DrawBarFinancialAnalysis2v :id="'ebitMultiple'" :chartData="pageInfo.ebitMultiple" v-if="initStatus"
						:gradeStatus="ebitMultipleGradeStatus" />
					<div class="pagination">9</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-ten">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon17.png" class="icon_" alt="心流">
						<span>盈利分析</span>
					</div>
					<DrawBarFinancialAnalysis2v :id="'grossProfitRatio'" :chartData="pageInfo.grossProfitRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<DrawBarFinancialAnalysis2v :id="'netProfiltRatio'" :chartData="pageInfo.netProfiltRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<div class="pagination">10</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-eleven">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon18.png" class="icon_" alt="心流">
						<span>成长分析</span>
					</div>
					<DrawBarFinancialAnalysis2v :id="'mainIncomeRatio'" :chartData="pageInfo.mainIncomeRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<DrawBarFinancialAnalysis2v :id="'netAssetRatio'" :chartData="pageInfo.netAssetRatio" v-if="initStatus"
						:gradeStatus="gradeStatus" />
					<div class="pagination">11</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-twelve">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon19.png" class="icon_" alt="心流">
						<span>营运分析</span>
					</div>
					<DrawBarFinancialAnalysis2v :id="'receivablesTurnoverRatio'" :chartData="pageInfo.receivablesTurnoverRatio"
						v-if="initStatus" :gradeStatus="receivablesTurnoverRatioGradeStatus" />
					<DrawBarFinancialAnalysis2v :id="'inventoryTurnoverRatio'" :chartData="pageInfo.inventoryTurnoverRatio"
						v-if="initStatus" :gradeStatus="inventoryTurnoverRatioGradeStatus" />
					<div class="pagination">12</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content page-dupon" id="page-dupon">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon20.png" class="icon_" alt="心流">
						<span>杜邦分析</span>
					</div>
					<DupontAnalyze v-if="initStatus" :versions="2" :dupontData="pageInfo.dubangAnalysis" />
					<div class="pagination">13</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-fourteen">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon21.png" class="icon_" alt="心流">
						<span>财务体检结果</span>
					</div>
					<ul class="financialSituation">
						<li v-for="(item,index) in pageInfo.financial" :key="index + 7">
							<div :style="{backgroundImage:'url('+item.url+')'}">
								<p :style="{color:item.color}">{{item.value}}%</p>
								<p :style="{color:item.color}">{{item.name}}</p>
							</div>
							<div>
								<p>{{item.name}}</p>
								<p>{{item.description}}</p>
							</div>
						</li>
					</ul>
					<div class="pagination">14</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-fifteen">
					<LibTableComponent1 :liaTableInfo="pageInfo.liaTableInfo" v-if="initStatus" :year="pageInfo.year"
						:versions="2" :companyName="pageInfo.baseInfo.companyName" />
					<ProfitTableComponent1 :profitTableInfo="profitTableInfo" v-if="initStatus" :year="pageInfo.year"
						:versions="2" :companyName="pageInfo.baseInfo.companyName" />
					<div class="pagination">15</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-sixteen">
					<FinancialRatios :financeRatio="financeRatio" v-if="initStatus" :year="pageInfo.year" :versions="2"
						:companyName="pageInfo.baseInfo.companyName" />
					<div class="pagination">16</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="computational">
					<p>
						<span>附件3</span>
						<span>计算公式</span>
					</p>
					<div class="formula-container">
						<h3>1、估值方法公式</h3>
						<ul>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>FCFF计算公式:</p>
								</div>
								<p>FCFF=净利润+利息费用+非现金支出-营运资本支出-资本性支出。系统通过该公式计算企业无杠杆自由现金流。</p>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>WACC(加权平均资本成本)计算公式:</p>
								</div>
								<p> WACC=(债务/总资本)×债务成本×(1-企业所得税税率)+(资产净值/总资本)×股权成本。心流深入
									研究A股、新三板等板块市场风险、行业风险，整理统计出行业资本结构、股权成本、债权成本等，系统 将根据企业所填信息，自动选取对应行业WACC数值。</p>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>GGM(Gorden Growth Model)</p>
								</div>
								<img src="../../assets/img/report/formulary.png" alt="公式-心流">
								<p>系统根据该公式计算该模型下的企业终值，并加以折现，计算企业现值。</p>
							</li>
						</ul>
						<h3>2、财务指标公式</h3>
						<ul>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>资产负债率=负债合计/资产总计×100%</p>
								</div>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>流动比率=流动资产合计/流动负债合计</p>
								</div>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>EBIT利息保障倍数=EBIT/利息费用</p>
								</div>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>毛利率=（营业收入-营业成本）/营业收入×100%</p>
								</div>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>净利润率=净利润/营业收入×100%</p>
								</div>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>主营业务收入增长率=（本年度营业收入-上年度营业收入）/上年度营业收入×100%</p>
								</div>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>净资产收益率=净利润×2/（本年度期初净资产+本年度期末净资产）×100%</p>
								</div>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>应收账款周转率=营业收入/平均应收账款</p>
								</div>
							</li>
							<li>
								<div>
									<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
									<p>存货周转率=营业成本/平均存货</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="pagination">17</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="disclaimer">
					<div class="page-last">
						<img src="../../assets/img/report/map@2x.png" alt="线上估值_初创期-心流" />
						<div class="flow-statement">
							<img src="../../assets/img/logo/flowWisdomBlack.png" alt="线上估值_初创期-心流" />
							<ul>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
										<p>估值声明</p>
										<p class="dian dian_"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										本报告由系统自动生成。该系统依据企业提供的财务数据和软性指标，结合具备可靠性水平的数据库，根据通用的行业业绩及财务预测逻辑和规则，对企业估值所需要的必要参数进行预测和估算，企业提供资料的完整度和准确性对最终估值结果有重要影响。请本报告使用者知悉。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
										<p>免责声明</p>
										<p class="dian dian_"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对企业整体价值衡量的参考，非并购、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p>心流慧估</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p class="dian dian_"><img src="../../assets/img/report/statement-01@2x.png" alt="icon-心流"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main lastStatement-main">
										<p>
											北京心流慧估科技有限公司是国内外领先的投行AIGC公司，专注于用AI技术为中国众多中小企业提供“估值+组合式金融服务”，通过自主研发的曼殊AI（Manju
											AI）系列软件产品，从发现、培育优质股权，再到资本对接，引金融之水精确“滴灌”，低成本、高效率地为中小企业走进资本市场提供定制化解决方案。
										</p>
										<p>
											心流慧估运用人工智能技术和多种统计分析方法，将国际通用估值技术结合心流慧估独创软性指标体系，搭建出完善的股权估值模型和算法模型。采用AIGC技术将估值模型、对标企业、指标权重、语句分析自动匹配并实时在线生成完整专业报告，不仅能科学、客观、公允地评估企业价值，还能帮助企业更好地进行融资规划、财务系统建设、企业管理规范，提高企业决策和融资效率。
										</p>
										<div>
											<img src="../../assets/img/report/wechartCode.jpg" alt="">
											<ul>
												<li><span>网址</span>：www.flcccc.com</li>
												<li><span>客服电话</span>：17610076778</li>
												<li><span>微信号</span>：flcccc888</li>
												<li><span>地址</span>：北京市朝阳区酒仙桥路甲12号1号楼15层1505-1室</li>
											</ul>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<div class="pagination">18</div>
				</div>
			</div>



		</div>

		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>

	</div>
</template>

<script>
	import qs from 'qs'
	import '../../assets/css/loading.css'
	import '../../assets/css/common.css'
	import PdfOnload from '../../utils/pdf'
	import Draw3DPieCanvas from '../../common/echarts/draw3DPieCanvas.vue'
	import DrawPileBar from '../../common/echarts/drawPileBar.vue'
	import DrawBarLine from '../../common/echarts/drawBarLine.vue'
	import DrawRadarCanvas from '../../common/echarts/drawRadarCanvas.vue'
	import DrawBarFinancialAnalysis2v from '../../common/echarts/drawBarFinancialAnalysis2v.vue'
	import DupontAnalyze from '../../common/reportComponent/dupontAnalyze.vue'
	import LibTableComponent1 from '../../common/reportComponent/liaTableComponent1.vue'
	import ProfitTableComponent1 from '../../common/reportComponent/profitTableComponent1.vue'
	import FinancialRatios from '../../common/reportComponent/financialRatios.vue'
	import wiseEstimateWatermark from '@/assets/img/logo/wiseEstimateWatermark.png'


	export default ({
		components: {
			Draw3DPieCanvas,
			DrawPileBar,
			DrawBarLine,
			DrawRadarCanvas,
			DrawBarFinancialAnalysis2v,
			DupontAnalyze,
			LibTableComponent1,
			ProfitTableComponent1,
			FinancialRatios
		},
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : "",
				pdfArr: [],
				pdfIndex: 0,
				pageInfo: {
					isTestAccount: null, //0:无水印可下载，1：有水印不可下载
					baseInfo: {
						companyName: "",
						establishDate: ""
					},
					reportNumber: "",
					year: [],
					absoluteValuation: {
						ggm: ""
					}
				},
				holderRation: [],
				valueResultNumber: [], //估值结果的数字
				machineLearningValuationResults: [], //机器学习估值法结果特殊数字
				absoluteValuationResult: [], //绝对估值结果特殊数字
				profitTableInfo: {}, //机器学习法页中利润表
				nowYear: "",
				scoreText: "",
				// 电池展示状态
				gradeStatus: 1,
				ebitMultipleGradeStatus: 1,
				receivablesTurnoverRatioGradeStatus: 1,
				inventoryTurnoverRatioGradeStatus: 1,
				income: [],
				cost: [],
				businessProfit: [],
				totalProfit: [],
				netProfit: [],
				profitDataYearX: ['140', '260', '380'],
				profitDataIncomeX: ['160', '280', '400'],
				profitDataCostX: ['152', '270', '385'],
				businessProfitX: ['145', '262', '378'],
				totalProfitX: ['135', '255', '368'],
				netProfitX: ['130', '245', '358'],
				financeRatio: {}
			}
		},
		mounted() {
			if (this.reportId) {
				this.initPage();
			}
		},
		methods: {
			initPage: function() {
				var that = this;
				var queryObj = {
					reportId: that
						.reportId //6f219310ce054632a8bdb6048c60d774  6d6f50d4f7c04b9bb35b2d83621d2edf
				}
				that.$post(this.domain.turingProject + "valuationReport/qryTuringValuationReport", qs.stringify(
					queryObj)).then(
					res => {
						var data = res.data;
						console.log(data)
						if (data.code == 200 && data.content) {
							that.pageInfo = data.content;
							// 设置估值结果页特殊数字
							var minArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.min.split('');
							var maxArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.max.split('');
							minArr.push('12')
							var newArr = minArr.concat(maxArr);
							this.specialNumeric(newArr, "valueResultNumber")

							// 股权结构
							var holderArr = [];
							var holderRation = JSON.parse(that.pageInfo.baseInfo.holderRation);
							var newHolderRation = [];
							newHolderRation = holderRation;
							for (var i = 0; i < newHolderRation.length; i++) {
								holderArr.push({
									name: newHolderRation[i].name,
									y: Number(newHolderRation[i].value),
									sliced: true,
									selected: true
								})
							}
							that.pageInfo.baseInfo.holderRation = holderArr;

							// 机器学习估值法
							that.profitTableInfo = JSON.parse(that.pageInfo.profitTableInfo);
							that.income = that.profitTableInfo.income.value;
							that.cost = that.profitTableInfo.cost.value;
							that.businessProfit = that.profitTableInfo.businessProfit.value;
							that.totalProfit = that.profitTableInfo.totalProfit.value;
							that.netProfit = that.profitTableInfo.netProfit.value;
							if (that.pageInfo.valuationReplacement ==
								0) { //valuationReplacement为0不替换，为1时图灵估值结果替换成相对估值结果
								var machineLearning = this.method.technologyNum(that.pageInfo
									.turingValuationValue).data.split('');
								if (that.pageInfo.turingValuationValue < 0) {
									var Minus = 2;
								} else {
									var Minus = 1;
								}
							} else {
								var machineLearning = this.method.technologyNum(that.pageInfo
									.relativeValuationValue).data.split('');
								if (that.pageInfo.relativeValuationValue < 0) {
									var Minus = 2;
								} else {
									var Minus = 1;
								}
							}
							this.specialNumeric(machineLearning, "machineLearningValuationResults", Minus)




							// 绝对估值
							if (that.pageInfo.absoluteValuation) {
								that.pageInfo.absoluteValuation = JSON.parse(that.pageInfo.absoluteValuation);
								that.nowYear = parseInt(that.pageInfo.createTime.split("-")[0]);
								var absoluteValuationNum = this.method.technologyNum(that.pageInfo
									.absoluteValuation.ggm).data.split(
									'');
								if (that.pageInfo.absoluteValuation.ggm < 0) {
									var ggmMinus = 2;
								} else {
									var ggmMinus = 1;
								}
								this.specialNumeric(absoluteValuationNum, "absoluteValuationResult", ggmMinus)
							}
							// 心流图谱
							that.pageInfo.question = JSON.parse(that.pageInfo.question);
							// 财务分析
							that.pageInfo.financial = JSON.parse(that.pageInfo.financial);

							// 分值说明
							var scoreText = "";
							for (var i = 0; i < that.pageInfo.question.length; i++) {
								if (i != that.pageInfo.question.length - 1) {
									scoreText += that.pageInfo.question[i].name + that.pageInfo.question[i]
										.value + '分、'
								} else {
									scoreText += that.pageInfo.question[i].name + that.pageInfo.question[i]
										.value + '分。'
								}
							}
							that.scoreText =
								'心流估值图谱是以企业竞争力为核心，根据影响企业估值的内外因素形成的企业评价体系，具体包括行业、产品、团队、技术、风控五方面。五项指标分别对应不同的问题，根据企业的答题情况结合相应的打分规则最终得出的五项指标的得分，如图谱所示：' +
								scoreText;

							// 行业
							that.pageInfo.industry = JSON.parse(that.pageInfo.industry);
							// 产品
							that.pageInfo.competitivePower = JSON.parse(that.pageInfo.competitivePower);
							// 团队
							that.pageInfo.teamManage = JSON.parse(that.pageInfo.teamManage);
							// 技术
							that.pageInfo.technology = JSON.parse(that.pageInfo.technology);
							// 风控
							that.pageInfo.ventureManage = JSON.parse(that.pageInfo.ventureManage);

							// 偿债分析资产负债率
							that.pageInfo.liabilitiesRatio = JSON.parse(that.pageInfo.liabilitiesRatio);
							that.pageInfo.liabilitiesRatio.title = "企业资产负债率为" + that.pageInfo.liabilitiesRatio
								.value[0] + "%";
							// 偿债分析资流动比率
							that.pageInfo.flowRation = JSON.parse(that.pageInfo.flowRation);
							that.pageInfo.flowRation.title = "企业流动比率为" + that.pageInfo.flowRation.value[0];
							// 偿债分析EBIT利息保障倍数
							that.pageInfo.ebitMultiple = JSON.parse(that.pageInfo.ebitMultiple);
							that.pageInfo.ebitMultiple.title = "企业EBIT利息保障倍数" + that.pageInfo.ebitMultiple
								.value[0];
							if (that.pageInfo.ebitMultiple.value[0] == 0) {
								that.ebitMultipleGradeStatus = 0;
							} else {
								that.ebitMultipleGradeStatus = 1;
							}
							// 盈利分析毛利率
							that.pageInfo.grossProfitRatio = JSON.parse(that.pageInfo.grossProfitRatio);
							that.pageInfo.grossProfitRatio.title = "企业毛利率为" + that.pageInfo.grossProfitRatio
								.value[0] + "%";
							// 盈利分析净利润率
							that.pageInfo.netProfiltRatio = JSON.parse(that.pageInfo.netProfiltRatio);
							that.pageInfo.netProfiltRatio.title = "企业净利润率为" + that.pageInfo.netProfiltRatio
								.value[0] + "%";
							// 盈利分析主营业务收入增长率
							that.pageInfo.mainIncomeRatio = JSON.parse(that.pageInfo.mainIncomeRatio);
							that.pageInfo.mainIncomeRatio.title = "企业主营业务收入增长率为" + that.pageInfo
								.mainIncomeRatio
								.value[0] + "%";
							// 盈利分析净资产收益率
							that.pageInfo.netAssetRatio = JSON.parse(that.pageInfo.netAssetRatio);
							that.pageInfo.netAssetRatio.title = "企业净资产收益率为" + that.pageInfo.netAssetRatio
								.value[0] + "%";
							// 营运分析应收账款周转率
							that.pageInfo.receivablesTurnoverRatio = JSON.parse(that.pageInfo
								.receivablesTurnoverRatio);
							that.pageInfo.receivablesTurnoverRatio.title = "企业应收账款周转率为" + that.pageInfo
								.receivablesTurnoverRatio
								.value[0];
							if (that.pageInfo.receivablesTurnoverRatio.value[0] == 0) {
								that.receivablesTurnoverRatioGradeStatus = 0;
							} else {
								that.receivablesTurnoverRatioGradeStatus = 1;
							}
							// 营运分析存货周转率
							that.pageInfo.inventoryTurnoverRatio = JSON.parse(that.pageInfo
								.inventoryTurnoverRatio);
							that.pageInfo.inventoryTurnoverRatio.title = "企业存货周转率为" + that.pageInfo
								.inventoryTurnoverRatio
								.value[0];
							if (that.pageInfo.inventoryTurnoverRatio.value[0] == 0) {
								that.inventoryTurnoverRatioGradeStatus = 0;
							} else {
								that.inventoryTurnoverRatioGradeStatus = 1;
							}

							// 杜邦分析
							if (that.pageInfo.dubangAnalysis) {
								that.pageInfo.dubangAnalysis = JSON.parse(that.pageInfo.dubangAnalysis);
							}

							// 财务状况
							that.pageInfo.financial = that.financialFormate(that.pageInfo.financial);
							// 资产负债表
							that.pageInfo.liaTableInfo = JSON.parse(that.pageInfo.liaTableInfo);
							// 财务比率表
							that.financeRatio = JSON.parse(that.pageInfo.financeRatio);

						}


						this.$nextTick(() => {
							if (this.pageInfo.isTestAccount == 1 && this.$route.query.admin != 1) {
								$('.page .content').append('<div class="watermark"><img src="' + wiseEstimateWatermark +
									'" alt="水印" /></div>')
							}
						})


					})

			},
			// 特殊数字的转换
			specialNumeric: function(data, key, minus) { //minus==1 代表正数   minus==2代表负数
				if (minus == 2) {
					this[key].push({
						val: '12',
						top: "0px"
					})
				}
				data.forEach((val, i) => {
					if (val == ".") {
						this[key].push({
							val: "11",
							top: "33px"
						})
					} else {
						this[key].push({
							val: val,
							top: "0px"
						})
					}
				})
			},
			// 日期格式化
			dateFormate: function(date) {
				if (date) {
					var dataStr = date.split("-");
					var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
					return newDataStr;
				}
			},
			// 得分校对
			scoreFormate: function(score) {
				if (this.pageInfo.baseInfo.companyName) {
					for (var i = 0; i < this.pageInfo.question.length; i++) {
						if (this.pageInfo.question[i].name == score) {
							return this.pageInfo.question[i].value + '分';
						}
					};
				}
			},
			// 估值结果格式化
			resultFormate: function(min, max) {
				var val = '';
				if (min) {
					return this.method.valuationResultConversion(min, max)
				} else {
					return '无数据'
				}
			},
			// 
			valueFormate: function(val) {
				if (val) {
					return this.method.resultConversion(val)
				} else {
					return '无数据'
				}
			},
			/*页脚日期格式转换*/
			replaceAll: function(val) {
				if (val) {
					return val.replace(new RegExp("-", "gm"), ".");
				}

			},
			// 财务状况格式化
			financialFormate: function(arr) { // name description value
				var key = '';
				for (var i = 0; i < arr.length; i++) {
					if (arr[i].name == '偿债能力') {
						key = 'debtPayingAbility';
						if (60 >= arr[i].value && arr[i].value > 0) {
							arr[i].color = '#0097A7';
						} else {
							arr[i].color = '#fff';
						}
					} else if (arr[i].name == '盈利能力') {
						key = 'profitability';
						if (60 >= arr[i].value && arr[i].value > 0) {
							arr[i].color = '#7600FF';
						} else {
							arr[i].color = '#fff';
						}
					} else if (arr[i].name == '成长能力') {
						key = 'growthAbility';
						if (60 >= arr[i].value && arr[i].value > 0) {
							arr[i].color = '#D400FF';
						} else {
							arr[i].color = '#fff';
						}
					} else if (arr[i].name == '营运能力') {
						key = 'operationCapacity';
						if (60 >= arr[i].value && arr[i].value > 0) {
							arr[i].color = '#0F3AFD';
						} else {
							arr[i].color = '#fff';
						}
					}

					if (10 >= arr[i].value && arr[i].value > 0) {
						arr[i].url = require('../../assets/img/report/' + key + '1.png')
					} else if (20 >= arr[i].value && arr[i].value > 11) {
						arr[i].url = require('../../assets/img/report/' + key + '2.png')
					} else if (30 >= arr[i].value && arr[i].value > 21) {
						arr[i].url = require('../../assets/img/report/' + key + '3.png')
					} else if (40 >= arr[i].value && arr[i].value > 31) {
						arr[i].url = require('../../assets/img/report/' + key + '4.png')
					} else if (50 >= arr[i].value && arr[i].value > 41) {
						arr[i].url = require('../../assets/img/report/' + key + '5.png')
					} else if (60 >= arr[i].value && arr[i].value > 51) {
						arr[i].url = require('../../assets/img/report/' + key + '6.png')
					} else if (70 >= arr[i].value && arr[i].value > 61) {
						arr[i].url = require('../../assets/img/report/' + key + '7.png')
					} else if (80 >= arr[i].value && arr[i].value > 71) {
						arr[i].url = require('../../assets/img/report/' + key + '8.png')
					} else if (90 >= arr[i].value && arr[i].value > 81) {
						arr[i].url = require('../../assets/img/report/' + key + '9.png')
					} else if (100 >= arr[i].value && arr[i].value > 91) {
						arr[i].url = require('../../assets/img/report/' + key + '10.png')
					}
				}
				return arr;
			},
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + '图灵估值报告.pdf'
				var pageArr = document.getElementsByClassName("content");
				this.pdfArr = [];
				// this.pdfArr.push('homePage')
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				console.log(this.pdfArr)
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			}

		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			}
		}

	})
</script>


<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	.page {
		width: 100%;
		height: 1754px;
		position: relative;
	}

	.content {
		width: 1240px;
		height: 100%;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border-top: 1px solid #ccc;
	}

	.page-content {
		padding-top: 124px;
		padding-left: 118px;
		padding-right: 118px;
	}

	.pagination {
		width: 126px;
		height: 27px;
		font-size: 20px;
		line-height: 29px;
		color: #fff;
		text-align: center;
		position: absolute;
		bottom: 37px;
		left: 50%;
		margin-left: -63px;
		background: url(../../assets/img/report/pageNumber.png) bottom center no-repeat;
		background-size: 100% 100%;
	}

	/* 封面 */

	#homePage {
		overflow: hidden;
		background: url(../../assets/img/report/pageIndex_bg.png)no-repeat center center;
	}

	.logoBox {
		overflow: hidden;
	}

	.homePage h1 {
		width: 293px;
		height: 99px;
		background: url(../../assets/img/logo/flowWisdomBlack.png)no-repeat center center;
		background-size: 100%;
		margin: 81px 0 0 75px;
		float: left;
	}

	.homePage h4 {
		font-size: 24px;
		font-weight: 400;
		line-height: 100%;
		color: #000;
		text-align: center;
		float: right;
		margin: 129px 120px 0 0;
	}

	.homePage .content {
		border: none;
	}

	.companyName {
		font-size: 50px;
		font-weight: 400;
		color: #0478fc;
		text-align: center;
		margin: 118px 0 40px 0;
	}

	.homePage>div>div.homePageLine {
		width: 796px;
		height: 6px;
		background: #0478fc;
		margin: 0 auto;
	}

	.homePage h2 {
		font-size: 110px;
		font-weight: bold;
		color: #0478fc;
		margin: 40px 0 10px 0;
		text-align: center;
	}

	.homePage h3 {
		font-size: 34px;
		font-weight: bold;
		color: #388BFF;
		text-align: center;
		margin-bottom: 22px;
	}

	.page-one-footer {
		position: absolute;
		bottom: 50px;
		height: 21px;
		width: 1100px;
		margin: 0 34px;
	}

	.page-one-footer>p {
		float: right;
		margin-right: 193px;
		font-size: 18px;
		color: #282828;
		line-height: 1;
	}

	.page-one-footer>p>span:nth-child(2) {
		margin: 0 5px;
	}

	.page-one-footer>p>span:last-of-type {
		color: #0D0D0D;
	}

	.page-one-footer>div.date {
		float: right;
		font-size: 18px;
		line-height: 1;
	}

	/* 新目录 */

	.page-catalogue h2 {
		font-size: 48px;
		font-weight: bold;
		color: #0F060B;
		margin: 172px auto 69px;
		text-align: center;
		line-height: 1;
	}

	.page-catalogue .generalize>div {
		width: 980px;
		height: 54px;
		margin: 34px auto 0px;
	}

	.page-catalogue .generalize>div p:first-child {
		font-size: 32px;
	}

	.page-catalogue .generalize p {
		float: left;
		font-size: 24px;
		font-weight: bold;
		color: #0F060B;
		line-height: 54px;
		margin-right: 11px;
	}

	.page-catalogue .generalize>div>div {
		width: 6px;
		height: 54px;
		background: #0478FC;
		float: left;
		margin: 0 28px 0 0;
	}

	.page-catalogue .generalize .dottedLine {
		float: left;
		height: 2px;
		width: 736px;
		margin: 14px 20px 0;
		background: url(../../assets/img/report/catalogue_.png)left center repeat;
	}

	.page-catalogue .generalize div>.dottedLine {
		margin: 28px 20px 0;
	}

	.page-catalogue .generalize .pageNumber {
		float: left;
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 100%;
		margin-top: 4px;
	}

	.page-catalogue .generalize div>.pageNumber {
		margin-top: 19px;
	}

	.page-catalogue .generalize ul {
		margin: 0 0 57px 194px;
	}

	.page-catalogue .generalize ul li {
		height: 30px;
		margin: 9px 0 13px 0;
		width: calc(980px - 64px);
	}

	.page-catalogue .generalize ul li p {
		font-size: 18px;
		font-weight: 400;
		color: #423838;
		line-height: 30px;
	}

	.page-catalogue .generalize ul li .dottedLine {
		width: 760px;
	}

	.page-catalogue .generalize ul.flowChart li .dottedLine {
		width: 797px;
	}

	// 盖章页
	.stamp {
		width: 952px;
		line-height: 42px;
		margin: 397px auto 47px;
		font-size: 20px;
		font-weight: 400;
		text-indent: 2em;
		margin-top: 31%;
		text-align: justify;
		color: #050407;
	}

	.detail_result {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
	}

	.detail_result h4 {
		float: left;
		height: 109px;
		line-height: 109px;
		border-top: 3px solid #4393FF;
		border-bottom: 3px solid #4393FF;
		font-size: 52px;
		color: #4393FF;
		padding: 0 34px;
		font-weight: bold;
	}

	.stamptime {
		width: 68%;
		margin: 10% auto;
		font-size: 20px;
		text-align: right;
		line-height: 39px;
		margin-top: 29%;
	}

	.stamptime>p {
		font-size: 20px;
		margin-top: 15px;
		line-height: normal;
	}

	.detail_result>div {
		position: relative;
	}

	.icon_ {
		width: 61px;
		height: 45px;
		float: left;
		margin-right: 25px;
		vertical-align: middle;
	}

	.icon_+span {
		font-size: 30px;
		font-weight: bold;
		color: #282828;
		line-height: 45px;
	}

	.valueResultCont {
		width: 1002px;
		height: 530px;
		padding-top: 31px;
		background: url(../../assets/img/report/valueResultContBj.png)center center repeat;
		background-size: 100% 100%;
		margin: 48px 0 30px;
		position: relative;
	}

	.valueResultCont>img {
		width: 575px;
		height: 124px;
		margin: 0 auto;
		display: block;
	}

	.numberBox {
		width: 575px;
		// height: 124px;
		// line-height: 84px;
		text-align: center;
		position: absolute;
		top: 18%;
		left: 50%;
		margin-top: -22px;
		margin-left: -287.5px;
	}

	.numberBox>img {
		margin-right: 6px;
		vertical-align: middle;
	}

	.numberBox>img:last-child {
		margin-right: 0;
	}

	.numberBox>p.unit {
		font-size: 24px;
		font-weight: bold;
		color: #3560F5;
		margin-left: 10px;
		position: relative;
		top: 13px;
		display: inline-block;
	}

	.numberBox>span.valuationMethod {
		font-size: 22px;
		font-weight: bold;
		color: #282828;
	}

	.sampleGraph {
		height: 600px;
		position: relative;
		left: 35px;
	}

	.sampleGraph div {
		position: absolute;
	}

	.sampleGraph div p.exampleExplanation {
		font-size: 20px;
		color: #282828;
		line-height: 28px;
	}

	.sampleGraph div p.exampleData {
		font-size: 22px;
		font-weight: bold;
		color: #282828;
	}

	.sampleGraph img {
		position: absolute;
	}

	.sampleGraph img:nth-of-type(2) {
		top: 71px;
		left: 186px;
	}

	.sampleGraph img:nth-of-type(3) {
		top: 180px;
	}

	.sampleGraph img:nth-of-type(4) {
		top: 209px;
		left: 175px;
	}

	.sampleGraph img:nth-of-type(5) {
		top: 362px;
	}

	.sampleGraph img:nth-of-type(6) {
		top: 457px;
		left: 315px;
	}

	.sampleGraph img:nth-of-type(7) {
		top: 76px;
		left: 410px;
	}

	.sampleGraph img:nth-of-type(8) {
		top: 205px;
		left: 589px;
	}

	.sampleGraph img:nth-of-type(9) {
		top: 327px;
		left: 390px;
	}

	.sampleGraph img:nth-of-type(10) {
		top: 470px;
		left: 681px;
	}

	.sampleGraph img:nth-of-type(11) {
		top: 46px;
		right: 73px;
	}

	.sampleGraph img:nth-of-type(12) {
		top: 234px;
		right: 211px;
	}

	.sampleGraph img:nth-of-type(13) {
		top: 337px;
		right: 80px;
	}

	.sampleGraph div:nth-of-type(1) {
		top: 108px;
		left: 179px;
	}

	.sampleGraph div:nth-of-type(2) {
		top: 277px;
		left: 179px;
	}

	.sampleGraph div:nth-of-type(3) {
		top: 437px;
		left: 179px;
	}

	.sampleGraph div:nth-of-type(4) {
		top: 170px;
		left: 589px;
	}

	.sampleGraph div:nth-of-type(5) {
		top: 442px;
		left: 570px;
	}

	.sampleGraph div:nth-of-type(6) {
		top: 238px;
		right: 97px;
	}

	.sampleGraph div:nth-of-type(7) {
		width: 320px;
		top: 524px;
		right: 9px;
		text-align: center;
	}

	#page-one>p {
		width: 1002px;
		margin: 0 auto;
	}

	#page-one>p:nth-of-type(1) {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		line-height: 29px;
		margin-top: 67px;
		margin-bottom: 20px;
	}

	#page-one>ul li {
		font-size: 18px;
		color: #282828;
		line-height: 24px;
		text-indent: -1.6em;
		margin-left: 29px;
		margin-bottom: 20px;
	}

	// 基本信息页
	.baseInfoCont,
	.productCont,
	.groupCont,
	.mainBusinessCont,
	.situationCont {
		overflow: hidden;
		padding: 20px 0 67px;
		margin-left: 85px;
	}

	.situationCont ul li,
	.baseInfoCont ul li {
		width: 50%;
		float: left;
	}

	.situationCont ul li span,
	.baseInfoCont ul li span,
	.productCont ul li span,
	.groupCont ul li span {
		font-size: 20px;
		font-weight: 400;
		color: #010000;
		line-height: 38px;
	}

	.statePlay {
		overflow: hidden;
	}

	.statePlay>div {
		width: 50%;
		float: left;
	}

	.mainBusinessCont>p {
		font-size: 20px;
		color: #282828;
		line-height: 40px;
		text-align: justify;
	}

	.equityStructure {
		height: 700px;
		position: relative;
	}

	.piePedestal {
		width: 600px;
		position: absolute;
		top: 250px;
		left: 50%;
		margin-left: -289px;
	}

	// 机器学习估值法
	#page-three>ul,
	#page-four>ul {
		margin-top: 23px;
	}

	#page-three>ul li,
	#page-four>ul li {
		font-size: 20px;
		color: #282828;
		line-height: 34px;
		text-indent: -1.6em;
		text-align: justify;
		margin-left: 89px;
	}

	.profitData {
		width: 551px;
		height: 403px;
		background: url(../../assets/img/report/profit.png)center center repeat;
		background-size: 100% 100%;
		margin: 0 auto;
		position: relative;
	}

	.profitData svg:nth-of-type(1) {
		position: relative;
		top: 0;
	}

	.rotateYear {
		font-size: 18px;
		fill: #fff;
		font-weight: bold;
	}

	.rotateData,
	.rotateDataBlue {
		width: 50px;
		background: pink;
		font-size: 14px;
		text-anchor: middle;
	}

	.rotateData {
		fill: #fff;
	}

	.rotateDataBlue {
		fill: #000898;
	}

	.arrows {
		height: 51px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -28px;
	}

	.arrows+img {
		height: 348px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -40px;
	}

	.specialNumeric {
		width: 1002px;
		margin: 0 auto;
		background-size: 100% 100%;
		position: relative;
	}

	.specialNumeric>img {
		display: block;
		margin: 0 auto;
	}

	.machineLearningValuationResults {
		height: 475px;
		padding-top: 37px;
		top: -26px;
		background: url(../../assets/img/report/machineLearningValuationResults.png)center center repeat;
	}

	.machineLearningValuationResults .numberBox {
		top: 117px;
	}

	.absoluteValuationMethod {
		height: 492px;
		margin-top: 11px;
		padding-top: 20px;
		background: url(../../assets/img/report/absoluteValuation.png)center center repeat;
	}

	.absoluteValuationMethod .numberBox {
		top: 110px;
	}

	// 第四页绝对估值
	.table_area {
		width: 1008px;
		height: 480px;
		margin: 0 auto;
		margin-top: 47px;
		overflow: hidden;
	}

	.cash-flow {
		float: left;
		width: 466px;
		height: 450px;
	}

	#cashFlowTrend {
		float: right;
	}

	.center-arrow {
		width: 74px;
		height: 59px;
		padding-top: 212px;
	}

	.center-arrow>img {
		width: 100%;
	}


	/*虚线框*/

	.dashes-line {
		width: 770px;
		height: 55px;
		margin: 0 auto;
		background: url(../../assets/img/report/dottedPicX.png)no-repeat;
		background-size: 100% 100%;
	}

	.arrow-down {
		width: 133px;
		height: 130px;
		margin: 0 auto;
		margin-top: 8px;
		background: url(../../assets/img/report/profession_page04_down_arrow.png) center center no-repeat;
		background-size: 100%;
	}

	.arrow-down>p {
		font-size: 14px;
		color: rgba(255, 255, 255, 1);
		line-height: 18px;
		text-align: center;
		padding-top: 55px;
	}

	.radarImage {
		height: 770px;
		position: relative;
	}

	.radarImage>img {
		position: absolute;
		top: 400px;
		left: 50%;
		margin-left: -365px;
	}

	#flowDiagnose_BaseInfo,
	#financeAnalyze_BaseInfo {
		width: 1020px;
		height: 500px;
		margin: 20px auto;
		opacity: .8;
		position: absolute;
		top: 70px;
		left: -11px;
	}

	.socre-explain-container p:first-child span {
		font-size: 22px;
		font-weight: bold;
		color: #282828;
	}

	.socre-explain-container p:last-child {
		margin-top: 5px;
		margin-bottom: 63px;
	}

	.socre-explain-container p:last-child span {
		font-size: 18px;
		color: #282828;
		line-height: 34px;
		text-indent: 2em;
		text-align: justify;
	}

	.flexibleIndex .industryTitle {
		margin-bottom: 37px;
	}

	.flexibleIndex .industryTitle span {
		font-size: 30px;
		font-weight: bold;
		color: #282828;
		line-height: 40px;
	}

	.flexibleIndex .industryTitle span:last-child {
		margin-left: 15px;
		font-size: 26px;
	}

	.flexibleIndex li {
		overflow: hidden;
		margin-bottom: 37px;
		margin-bottom: 41px;
	}

	.flexibleIndex li>div {
		float: left;
	}

	.flexibleIndex .grade {
		width: 182px;
		height: 156px;
		line-height: 27px;
		text-align: center;
		position: relative;
		margin-right: 69px;
		margin-top: 19px;
	}

	.flexibleIndex .grade img {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.flexibleIndex .grade p:nth-of-type(1) {
		font-size: 26px;
		font-weight: bold;
		color: #515FFF;
	}

	.flexibleIndex .grade p:nth-of-type(2) {
		font-size: 14px;
		color: #515FFF;
	}

	.flexibleIndex .industryDes {
		width: 75%;
	}

	.flexibleIndex .industryDes p:first-child span:first-child {
		width: 44px;
		height: 43px;
		line-height: 43px;
		text-align: center;
		color: #fff;
		font-size: 18px;
		font-weight: bold;
		background: url(../../assets/img/report/serialNumber.png) center center no-repeat;
		margin-right: 15px;
	}

	.flexibleIndex .industryDes p:first-child span:last-child {
		font-size: 22px;
		font-weight: bold;
		color: #515FFF;
		line-height: 43px;
	}

	.flexibleIndex .industryDes p:last-child {
		font-size: 18px;
		color: #282828;
		line-height: 34px;
		text-indent: 2em;
		text-align: justify;
		margin-left: 60px;
		margin-top: 15px;
	}

	#myCanvas {
		width: 100%;
		height: 500px;
		background: pink;
		position: relative;
	}

	// 财务状况
	.financialSituation {
		margin-top: 59px;
	}

	.financialSituation li {
		overflow: hidden;
		margin-bottom: 93px;
	}

	.financialSituation li>div {
		float: left;
	}

	.financialSituation li>div:first-child {
		width: 211px;
		height: 227px;
		line-height: 25px;
		text-align: center;
	}

	.financialSituation li>div:first-child p:first-child {
		font-size: 24px;
		font-weight: bold;
		margin-top: 32px;
	}

	.financialSituation li>div:first-child p:last-child {
		font-size: 18px;
	}

	.financialSituation li>div:last-child {
		width: 662px;
		margin-left: 97px;
	}

	.financialSituation li>div:last-child p:first-child {
		font-size: 22px;
		font-weight: bold;
		margin-top: 38px;
		margin-bottom: 18px;
	}

	.financialSituation li>div:last-child p:last-child {
		font-size: 20px;
		color: #282828;
		line-height: 34px;
		text-indent: 2em;
		text-align: justify;
	}

	.financialSituation li:nth-of-type(1)>div:last-child p:first-child {
		color: #06B4C6;
	}

	.financialSituation li:nth-of-type(2)>div:last-child p:first-child {
		color: #826DF0;
	}

	.financialSituation li:nth-of-type(3)>div:last-child p:first-child {
		color: #DF4CFD;
	}

	.financialSituation li:nth-of-type(4)>div:last-child p:first-child {
		color: #0178F5;
	}

	// 计算公式
	#computational>p span {
		font-size: 24px;
		color: #282828;
	}

	#computational>p span:last-child {
		margin-left: 382px;
	}

	.formula-container>h3 {
		font-size: 20px;
		line-height: 1;
		color: #282828;
		margin-bottom: 20px;
		margin-top: 28px;
	}

	.formula-container ul li div p.dian {
		width: 10px;
		float: left;
		text-align: center;
		margin-right: 10px;
	}

	.formula-container ul li div p.dian img {
		width: 10px;
		vertical-align: middle;
		margin-top: 9px;
	}

	.formula-container ul li div p.dian+p {
		font-size: 20px;
		color: #282828;
		margin-top: 20px;
	}

	.formula-container ul li>p {
		font-size: 18px;
		color: #282828;
		// line-height: 24px;
		margin-left: 17px;
		margin-top: 8px;
	}

	.formula-container ul li>img {
		margin: 11px 0 0 17px;
	}

	// 免责声明
	.page-last>img {
		width: 900px;
		height: 376px;
		margin: 0px auto 50px;
		display: block;
	}

	.flow-statement {
		width: 1008px;
		margin: 0 auto;
	}

	.flow-statement>img {
		display: block;
		width: 407px;
		margin: 0 auto;
	}

	.flow-statement>ul>li>div.statement-tit {
		height: 20px;
		width: 428px;
		margin: 50px auto 30px;
		font-size: 0;
		position: relative;
	}

	.statement-tit>span.xian {
		/*width: 100%;*/
		width: 135px;
		height: 1px;
		display: block;
		/*float: left;*/
		position: absolute;
		top: -1px;
		margin-top: 10px;
		background: #4393FF;
	}

	.statement-tit>span.xian:first-of-type {
		left: 2px;
	}

	.statement-tit>span.xian:last-of-type {
		right: 0;
	}

	.statement-tit>img {
		width: 12px;
		height: 12px;
		float: left;
		margin-top: 3px;
	}

	.statement-tit>img:first-of-type {
		margin-left: 135px;
	}

	.statement-tit>p {
		width: 136px;
		height: 20px;
		line-height: 20px;
		font-size: 22px;
		color: #282828;
		float: left;
		background: #FFF;
		text-align: center;
		font-weight: bold;
	}

	.statement-tit>p.dian {
		width: 11px;
		height: 11px;
		float: left;
		margin: -2px 0 0 136px;
	}

	.statement-tit>p.dian img {
		width: 100%;
	}

	.statement-tit>p.dian_ {
		margin: -2px 0 0 0;
	}

	.statement-main {
		width: 977px;
		padding: 10px 20px;
		background: #E8F4FF;
		font-size: 18px;
		color: #282828;
		line-height: 32px;
		text-indent: 2em;
		text-align: justify;
	}

	.statement-main div {
		margin: 24px 0 0 118px;
		overflow: hidden;
	}

	.statement-main div img {
		float: left;
		width: 138px;
		height: 138px;
		margin: 0 0 20px 0;
	}

	.statement-main div ul {
		margin: 4px 0 0 0;
	}

	.statement-main div ul li {
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 30px;
	}

	.statement-main div ul li span {
		width: 72px;
		text-indent: 0;
		text-align-last: justify;
		text-align: justify;
		text-justify: distribute-all-lines;
	}
</style>